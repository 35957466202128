<template>
    <!-- <div :class="customclass" style="padding-left :0px; padding-right :0px">
        <div class="card o-hidden mb-4">
            <div class="card-body">
                
                <div class="table-responsive">
                    <div class="row" style="margin-right :0px">
                        <div class="col-lg-2 pt-2" v-if="searcharea">
                            <div class="form-group">
                                

                            <select v-model="search_data.pagination" @change="$parent.search" style="height: 25px; padding-top: 2px;" 
                                class="col-12 form-control form-control-rounded">
                                <option value="10">10 row Showing</option>
                                <option value="25">25 row Showing</option>
                                <option value="50">50 row Showing</option>
                                <option value="100">100 row Showing</option>
                                <option value="200">200 row Showing</option>
                                <option value="500">500 row Showing</option>
                                <option value="999999999999999999999">
                                    All
                                </option>
                            </select>

                            </div>
                        </div>
                        <slot name="searcharea" v-if="searcharea"></slot>

                        <div class="col-lg-2" v-if="searcharea">
                            <div class="form-group">
                                <button class="btn btn-primary" @click="$parent.search()">Search</button>
                            </div>
                        </div>

                        <div class="col-12">
                            <slot name="customTable"></slot>
                        </div>


                        <div class="col-12" v-if="baseTable">
                            <table class="table table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th class="sl text-center">#</th>
                                        <th
                                            v-for="(column, index) in $parent.table.columns"
                                            :key="'a' + index"
                                            class="sort-th"
                                            :style="
                                            'text-align:' + column.align + '; width:' + column.width
                                            "
                                        >
                                            {{ column.title}}
                                        </th>

                                        <th
                                            width="10%"
                                            class="text-center"
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="!this.$root.spinner">
                                    <tr
                                        v-for="(item, index) in table.datas.data"
                                        :key="index"
                                        class="change_sorting"
                                        :class="
                                            'change_sorting' + item.sorting + ' update_item' + item.id
                                        ">

                                            <td>
                                                {{ table.meta.from + index }}
                                            </td>
                                            <slot
                                                v-for="(column) in table.columns"
                                                :name="column.field"
                                                :item="item"
                                                :key="column"
                                            >
                                                
                                                <td v-if="column.field == 'status'">
                                                    <template v-if="['deactive', 'pending'].includes(item[column.field])">
                                                        <span class="badge badge-pill badge-danger m-1">{{ item[column.field] }}</span>
                                                    </template>

<template v-else-if="item[column.field] == 'active'">
                                                        <span class="badge badge-pill badge-success m-1">{{ item[column.field] }}</span>
                                                    </template>

<template v-else>
                                                        <span class="badge badge-pill badge-success m-1">{{ item[column.field] }}</span>
                                                    </template>
</td>

<td v-else-if="column.image">
    <img :src="item[column.field]" width="70">
</td>

<td v-else-if="column.html">
    <span v-html="item[column.field]"></span>
</td>

<td v-else>
    {{ itemValue(item, column.field ?? null, column.subfield ?? null) }}

</td>


</slot>

<td width="13%">
    <div class="action-wrapper">

        <router-link :to="{name: model+'.show', params: {id: item.id}}" class="text-info mr-2" href="#">
            <i class="nav-icon fa fa-eye font-weight-bold"></i>
        </router-link>

        <router-link :to="{name: model+'.edit', params: {id: item.id}}" class="text-success mr-2" href="#">
            <i class="nav-icon fa fa-edit font-weight-bold"></i>
        </router-link>

        <a class="text-danger mr-2" @click="deleteData(item.id)">
            <i class="nav-icon fa fa-trash font-weight-bold"></i>
        </a>

        <slot v-if="table.routes.array">
            <slot v-for="(rt, index) in table.routes.array" :key="index">
                <slot v-if="rt.path != undefined">
                    <router-link :title="rt.title" target="_blank" :key="index" :to="{
                                                                    path: rt.path+'/'+item.id,
                                                                }" :class="'btn btn-xs btn-' + rt.btnColor"
                        class="delete edit">
                        <i :class="'fa fa-' + rt.icon"></i>
                    </router-link>
                </slot>
                <slot v-else>
                    <router-link :key="index" v-if="rt.route" :to="{
                                                                    name: rt.route,
                                                                    params: { id: item.id },
                                                                }" :class="'btn btn-xs btn-' + rt.btnColor"
                        class="delete edit">
                        <i :class="'fa fa-' + rt.icon"></i>
                    </router-link>
                </slot>
            </slot>
        </slot>
    </div>
</td>
</tr>

<slot v-if="table.datas.data">
    <tr v-if="Object.keys(table.datas.data).length == 0">
        <td :colspan="table.columns.length + 2" align="center">
            No data found
        </td>
    </tr>
</slot>
</tbody>

<tbody v-else>
    <tr>
        <td :colspan="Object.keys(table.columns).length + 2" style="text-align: center; background: #fff">
            <i class="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i>
        </td>
    </tr>
</tbody>
</table>
</div>
</div>


</div>
<Pagination v-if="paginate" />

</div>
</div>
</div> -->

    <div class="row">
        <div class="col-12">
            <div class="card"><!-- Card header -->
                <div class="card-header pb-0">
                    <div class=" d-flex">
                        <div>
                            <h5 class="mb-0">{{title}}</h5>
                        </div>
                        <div class="ms-auto my-auto mt-sm-5 mt-lg-0">
                            <div class="ms-auto my-auto" v-if="addButton">
                                <router-link :to="{name: model+'.create'}"
                                    class="btn btn-primary btn-sm mb-0">
                                    +&nbsp; Add
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row justify-content-center">
                        <slot name="searcharea" v-if="searcharea"></slot>

                        <div class="col-lg-2" v-if="searcharea">
                            <div class="form-group">
                                <button class="btn btn-primary" @click="$parent.search()">Search</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                            <slot name="customTable"></slot>
                        </div>

                    <div class="table-responsive" v-if=baseTable>
                        <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                            <div class="dataTable-top" style="padding-bottom: 0px !important; padding-top: 0px !important;">
                                <div class="dataTable-dropdown">
                                    <label>
                                    <select v-model="search_data.pagination" class="dataTable-selector" @change="$parent.search">
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="200">200</option>
                                            <option value="250">250</option>
                                            <option value="500">500</option>
                                            <option value="1000000">All</option>
                                        </select> entries per page
                                    </label>
                                </div>

                                <div class="dataTable-search">
                                   
                                </div>
                            </div>
                            <div class="dataTable-container">
                                <table class="table table-flush dataTable-table" id="products-list">
                                    <thead class="thead-light">
                                        <tr>
                                            <th class="sl text-center">#</th>
                                            <th
                                                v-for="(column, index) in $parent.table.columns"
                                                :key="'a' + index"
                                                class="sort-th"
                                                :style="
                                                'text-align:' + column.align + '; width:' + column.width
                                                "
                                            >
                                                {{ column.title}}
                                            </th>

                                            <th
                                                width="10%"
                                                class="text-center"
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="!this.$root.spinner" style="font-size: 13px">
                                        <tr
                                            v-for="(item, index) in table.datas.data"
                                            :key="index"
                                            class="change_sorting"
                                            :class="
                                                'change_sorting' + item.sorting + ' update_item' + item.id
                                            ">

                                                <td>
                                                    {{ table.meta.from + index }}
                                                </td>
                                                <slot
                                                    v-for="(column) in table.columns"
                                                    :name="column.field"
                                                    :item="item"
                                                    :key="column"
                                                >
                                                    
                                                    <td v-if="column.field == 'status'">
                                                        <template v-if="['deactive', 'pending'].includes(item[column.field])">
                                                            <span class="badge bg-label-warning m-1">{{ item[column.field] }}</span>
                                                        </template>

                                                        <template v-else-if="item[column.field] == 'active'">
                                                            <span class="badge bg-label-success badge-success m-1">{{ item[column.field] }}</span>
                                                        </template>

                                                        <template v-else>
                                                            <span class="badge bg-label-primary m-1">{{ item[column.field] }}</span>
                                                        </template>
                                                    </td>

                                                    <td v-else-if="column.image">
                                                        <img :src="item[column.field]" width="70">
                                                    </td>

                                                    <td v-else-if="column.html">
                                                        <span v-html="item[column.field]"></span>
                                                    </td>

                                                    <td v-else>
                                                        {{ itemValue(item, column.field ?? null, column.subfield ?? null) }}

                                                    </td>
                                                </slot>

                                                <td class="text-sm">
                                                    <div class="action-buttons" style="display: inline-flex; align-items: center;">

                                                        <router-link v-if="routes.show" :to="{name: model+'.show', params: {id: item.id}}">
                                                            <i class="ti ti-eye"></i>
                                                        </router-link>
                                                        <router-link v-if="routes.edit" :to="{name: model+'.edit', params: {id: item.id}}" class="mx-2">
                                                            <i class="ti ti-pencil ti-md"></i>
                                                        </router-link>
                                                        <a v-if="routes.delete" href="javascript:;" @click="deleteData(item.id)">
                                                            <i class="ti ti-trash"></i>
                                                        </a>
                                                    </div>

                                                    <slot v-if="table.routes.array">
                                                        <slot v-for="(rt, index) in table.routes.array" :key="index">
                                                            <slot v-if="rt.path != undefined">
                                                                <router-link :title="rt.title" target="_blank" 
                                                                    :key="index" :to="{
                                                                        path: rt.path+'/'+item.id,
                                                                    }" :class="'btn btn-xs btn-' + rt.btnColor"
                                                                    class="delete edit">
                                                                    <i :class="'fa fa-' + rt.icon"></i>
                                                                </router-link>
                                                            </slot>
                                                            <slot v-else>
                                                                <router-link :key="index" v-if="rt.route" :to="{
                                                                                                                name: rt.route,
                                                                                                                params: { id: item.id },
                                                                                                            }" :class="'btn btn-xs btn-' + rt.btnColor"
                                                                    class="delete edit">
                                                                    <i :class="'fa fa-' + rt.icon"></i>
                                                                </router-link>
                                                            </slot>
                                                        </slot>
                                                    </slot>
                                                </td>
                                        </tr>

                                        <slot v-if="table.datas.data">
                                            <tr v-if="Object.keys(table.datas.data).length == 0">
                                                <td :colspan="table.columns.length + 2" align="center">
                                                    No data found
                                                </td>
                                            </tr>
                                        </slot>
                                    </tbody>
                                    <tbody v-if="this.$root.spinner">
                                        <tr>
                                            <td :colspan="Object.keys(table.columns).length + 2" style="text-align: center; background: #fff">
                                                <i class="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <Pagination v-if="paginate" />                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseTable",
    props: {
        col: {
            type: String,
            default: 'col-12',
        },
        addButton:{
            type: Boolean,
            default: true,
        },
        customclass: {
            type: String,
            default: 'col-12',
        },
        title: {
            type: String,
            default: '',
        },
        model: {
            type: String,
            default: '',
        },
        searcharea: {
            type: Boolean,
            default: true,
        },
        baseTable: {
            type: Boolean,
            default: true
        },
        paginate: {
            type: Boolean,
            default: true,
        },
        routes: {
            type: Object,
            default: () => ({
                show: true,
                delete: true,
                edit: true,
            }),
        }
    },
    inject: {
        table: {
            from: 'table',
            default: [],
        },
        search_data: {
            from: 'search_data',
            default: {},
        }, model: {
            from: 'model',
            default: "",
        }, search: {
            from: 'search',
            default: "",
        }, json_fields: {
            from: 'json_fields',
            default: {},
        }
    },

    methods: {
        deleteData(id) {
            if (confirm('Are you confirm')) {
                this.destroy_data(this.model, id, this.$parent.search_data);
                this.$parent.search();
            }
        },
        itemValue(item, column, child = "") {
            var value = item[child ?? column];

            if (typeof value === 'object' && item[child ?? null] != null) {
                return value[column]
            }


            if (child) {
                let obj = item;
                value = String(child)
                    .split(".")
                    .reduce((p, c) => {
                        return (p && p[c] !== undefined && p[c] !== null) ? p[c] : undefined;
                    }, obj);
            }
            return value;

        },
    },

    created(){

    }
}
</script>