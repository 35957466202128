<template>

  <div class="layout-wrapper layout-content-navbar">
    <div class="layout-container">
      <Sidebar v-if="this.$route.name != 'login'"></Sidebar>

      <div class="layout-page">
        <Header v-if="this.$route.name != 'login'"></Header>

        <div :class="this.$route.name != 'login' ? '' : '' ">
          <div class="content-wrapper">
            <div class="container-xxl flex-grow-1 container-p-y">
              <router-view/>
            </div>
          </div>
          
        </div>
        <!-- <router-view/> -->

      </div>

    </div>
  </div>

    <!-- modal -->
    <div
      class="modal fade"
      id="validateModal"
      tabindex="-1"
      aria-labelledby="validateModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-black" id="validateModalLabel">
              Validation Fails
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div
              v-if="
                $root.validation_errors &&
                Object.keys($root.validation_errors).length > 0
              "
              class="col-12 py-2 mb-3"
              style="background: #fddede"
            >
              <div class="error p-2">
                <span
                  v-for="(err, errIndex) in $root.validation_errors"
                  :key="errIndex"
                  class="text-danger"
                >
                  {{ err[0] }}
                  <br />
                </span>
              </div>
            </div>
  
            <!-- exception_errors -->
            <div
              v-if="
                $root.exception_errors &&
                Object.keys($root.exception_errors).length > 0
              "
              class="col-12 py-2 mb-3"
              style="background: #fddede"
            >
              <div class="error p-2">
                <slot v-if="typeof $root.exception_errors === 'object'">
                  <slot v-for="(err, errIndex) in $root.exception_errors">
                    <span
                      v-if="typeof err === 'string'"
                      :key="errIndex"
                      class="text-danger"
                    >
                      {{ err }}
                      <br />
                    </span>
                  </slot>
                </slot>
                <slot v-else>
                  {{ $root.exception_errors }}
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

import Header from "./views/partials/Header.vue";
import Sidebar from "./views/partials/Sidebar.vue";


export default {
  name: 'App',
  data(){
    return{
      globalsearch: null,
      customer:null
    }
  },
  components: {
    Header, Sidebar
  },
  mounted() {
    const script = document.createElement('script');
    script.src = '/assets/js/main.js';
    script.onload = () => {
      console.log('Material Dashboard script loaded successfully.');
    };
    script.onerror = () => {
      console.error('Failed to load Material Dashboard script.');
    };
    document.head.appendChild(script);
  },
  methods:{
    search(){
      this.$root.spinner = true
      let phone = this.globalsearch 
      if (phone == undefined) {
          return ;
      }
      if (phone.length == 11) {
          this.axios.get('customer-info', {params: {
                phone, 
                singleData: true
            }
          }).then(res => {
              this.$root.spinner = false
              if (res.status == 200) {
                  this.customer = res.data.data;
              }
          });
      }
    },

    viewDetails(){
      this.$jQuery('.search-close').click();
      // window.location.href = process.env.VUE_APP_BASE_URL+'customer/'+this.customer.id
      this.$router.push({name: 'customer.show', params:{id : this.customer.id}})
    }

  }
}
</script>
