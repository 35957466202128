<template>
   <div class="row justify-content-center">
      <div class="col-lg-12 ">
        <span class="m-auto"></span>
          <div class="btn-group col-lg-4 col-sm-12 pl-sm-3" role="group">
            <div class="dropdown">
              <a class="btn bg-primary btn-sm dropdown-toggle" style="color:white" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{ type.replace(/_/g, ' ') }}
              </a>

              <ul class="dropdown-menu">
                <li><a class="dropdown-item" @click="getDashboard('today')" href="#">Today</a></li>
                <li><a class="dropdown-item" @click="getDashboard('last_seven_day')" href="#">Last 7 Days</a></li>
                <li><a class="dropdown-item" @click="getDashboard('last_thirty_day')" href="#">Last 30 Days</a></li>
                <li><a class="dropdown-item" @click="getDashboard('life_time')" href="#">Life Time</a></li>
              </ul>
            </div>
          
          </div>
      </div>
    </div>
    <section class="ul-widget-stat-s1 pt-2">
      <div class="row mb-4">
        <div class="col-sm-3">
          <div class="card">
            <div class="card-body p-3 position-relative">
              <div class="row">
                <div class="col-7 text-start">
                  <p class="text-sm mb-1 text-capitalize font-weight-bold">Orders</p>
                  <h5 class="font-weight-bolder mb-0">
                    {{ data.count?.order_count }}
                  </h5>
                  <span class="text-sm text-end text-success font-weight-bolder mt-auto mb-0">
                    {{ type.replace(/_/g, ' ') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-3 mt-sm-0 mt-4">
          <div class="card">
            <div class="card-body p-3 position-relative">
              <div class="row">
                <div class="col-12 text-start">
                  <p class="text-sm mb-1 text-capitalize font-weight-bold">Sales Amount</p>
                  <h5 class="font-weight-bolder mb-0">
                    {{ this.config.currency_symbol}}{{ data.count?.sales_amount }}
                  </h5>
                  <span class="text-sm text-end text-success font-weight-bolder mt-auto mb-0">
                    {{ type.replace(/_/g, ' ') }}
                  </span>
                </div>
               
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-3 mt-sm-0 mt-4">
          <div class="card">
            <div class="card-body p-3 position-relative">
              <div class="row">
                <div class="col-7 text-start">
                  <p class="text-sm mb-1 text-capitalize font-weight-bold">Cancel</p>
                  <h5 class="font-weight-bolder mb-0">
                    {{ data.count?.cancel_order }}
                  </h5>
                  <span class="font-weight-normal text-secondary text-sm">
                    <span class="font-weight-bolder text-success">
                      {{ type.replace(/_/g, ' ') }}
                    </span> 
                  </span>
                </div>
               
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-3 mt-sm-0 mt-4">
          <div class="card">
            <div class="card-body p-3 position-relative">
              <div class="row">
                <div class="col-7 text-start">
                  <p class="text-sm mb-1 text-capitalize font-weight-bold">Return</p>
                  <h5 class="font-weight-bolder mb-0">
                    {{ data.count?.return_order }}
                  </h5>
                  <span class="font-weight-normal text-secondary text-sm">
                    <span class="font-weight-bolder text-success">
                      {{ type.replace(/_/g, ' ') }}
                    </span> 
                  </span>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="card chart-card mb-4">
            <div class="card-body" v-if="data.order_status">
                <div class="card-title">Order Status</div>
                <Pie :data="data.order_status" :options="options" />
            </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="card chart-card mb-4">
            <div class="card-body" v-if="data.order_source">
                <div class="card-title">Order Source </div>
                <Bar :data="data.order_source" :options="options" />
            </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="card chart-card mb-4">
            <div class="card-body" v-if="data.cancel_order">
                <div class="card-title">Cancel Reason </div>
                <Pie :data="data.cancel_order" :options="options" />
            </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="card chart-card mb-4">
            <div class="card-body" v-if="data.return_order">
                <div class="card-title">Return Reason </div>
                <Pie :data="data.return_order" :options="options" />
            </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card chart-card mb-4">
            <div class="card-body" v-if="data.order_trend">
                <div class="card-title">Order Trend </div>
                <Line :data="data.order_trend" :options="options" />
            </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="card chart-card mb-4">
            <div class="card-body" v-if="data.top_10_location">
                <div class="card-title">Top 10 Location </div>
                <Bar :data="data.top_10_location" :options="options" />
            </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="card chart-card mb-4">
            <div class="card-body" v-if="data.top_10_location_by_value">
                <div class="card-title">Top 10 Location By Value </div>
                <Bar :data="data.top_10_location_by_value" :options="options" />
            </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="card chart-card mb-4">
            <div class="card-body" v-if="data.order_status_cycle_time">
                <div class="card-title">Order Status Cycle Time </div>
                <Bar :data="data.order_status_cycle_time" :options="options" />
            </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="card chart-card mb-4">
            <div class="card-body" v-if="data.top_10_product">
                <div class="card-title">Top 10 Product </div>
                <Bar :data="data.top_10_product" :options="options" />
            </div>
        </div>
      </div>

    </div>


</template>

<script>
import { Chart as ChartJS, Title, ArcElement, Tooltip, Legend, BarElement,
  CategoryScale,   LineElement, PointElement,
  LinearScale } from 'chart.js'
  import { Bar } from 'vue-chartjs'
  import { Pie } from 'vue-chartjs'
  import { Line } from 'vue-chartjs'

  // import { Bar } from 'vue-chartjs'

  ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement,PointElement, Title, ArcElement, Tooltip, Legend)

export default {
  name: 'Dashboard',
  components: {
    Pie, Bar, Line
  },
  data(){
    return{
      data:{
        count:{}
      },
      options: {},
      search_data:{},
      type: 'today'
    }
  },

  methods:{
    getDashboard(type){
      this.type = type
      this.axios.get('dashboard', {params:{type}}).then(res => {
        this.data = res.data.data
        console.log(res);
      })
    }
  },

  created(){
    //  this.data = {
    //   labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
    //   datasets: [
    //     {
    //       backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
    //       data: [40, 20, 80, 10]
    //     }
    //   ]
    // }

    this.options = {
      responsive: true,
      maintainAspectRatio: false
    }

    this.getDashboard('today');    

  }
}
</script>

<style scoped>
  .chart-card {
    height: 400px; /* or any desired fixed height */
  }

  .card-body {
      height: 100%;
      position: relative;
  }

  canvas{
    height:310px !important;
  }

</style>