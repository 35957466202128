<template>
  <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme" data-bg-class="bg-menu-theme" style="touch-action: none; user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);">
    <div class="app-brand demo">
      <router-link :to="{name: 'Home'}" class="app-brand-link">
        <span class="app-brand-logo demo">
          <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z" fill="#7367F0"></path>
            <path opacity="0.06" fill-rule="evenodd" clip-rule="evenodd" d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z" fill="#161616"></path>
            <path opacity="0.06" fill-rule="evenodd" clip-rule="evenodd" d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z" fill="#161616"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z" fill="#7367F0"></path>
          </svg>
          <!-- <img src="../../../public/assets/img/branding/tech-logo.png"> -->
        </span>
        <span class="app-brand-text demo menu-text fw-bold">Technossis</span>
      </router-link>

      <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto">
        <i class="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle"></i>
        <i class="ti ti-x d-block d-xl-none ti-sm align-middle"></i>
      </a>
    </div>

    <div class="menu-inner-shadow"></div>

    <ul class="menu-inner py-1 ps ps--active-y">
      <!-- Dashboards -->
      <li class="menu-item">
        <router-link :to="{name: 'Home'}" class="menu-link">
          <i class="menu-icon tf-icons ti ti-smart-home"></i>
          <div data-i18n="Dashboards">Dashboards</div>
        </router-link>
      </li>

      <!-- Layouts -->
      <li class="menu-item">
        <a href="javascript:void(0);" class="menu-link menu-toggle">
          <i class="menu-icon tf-icons fas fa-shopping-cart"></i>
          <div data-i18n="Orders">Orders</div>
        </a>

        <ul class="menu-sub">
          <li class="menu-item">
            <router-link :to="{name: 'order.index'}" class="menu-link">
              <div data-i18n="Order List">Order List</div>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link :to="{name: 'order.create'}" class="menu-link">
              <div data-i18n="Order Create">Order Create</div>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link :to="{name: 'orderreturn.index'}" class="menu-link">
              <div data-i18n="Return List">Return List</div>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link :to="{name: 'orderreturn.create'}" class="menu-link">
              <div data-i18n="Order Return Create">Order Return Create</div>
            </router-link>
          </li>
        </ul>
      </li>

      <li class="menu-item">
        <a href="javascript:void(0);" class="menu-link menu-toggle">
          <i class="menu-icon tf-icons fas fa-landmark"></i>
          <div data-i18n="Inventory">Inventory</div>
        </a>

        <ul class="menu-sub">
          <li class="menu-item">
            <router-link :to="{name: 'full.stock'}" class="menu-link">
              <div data-i18n="Full Stock Report"> Full Stock Report </div>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link :to="{name: 'shortage.alert'}" class="menu-link">
              <div data-i18n="Shortage Report">Shortage Report</div>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link :to="{name: 'stocktransfer.index'}" class="menu-link">
              <div data-i18n="Stock Transfer List">Stock Transfer List</div>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link :to="{name: 'stocktransfer.create'}" class="menu-link">
              <div data-i18n="Stock Transfer Create">Stock Transfer Create</div>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link :to="{name: 'stockadjustment.index'}" class="menu-link">
              <div data-i18n="Stock Transfer List">Stock Adjustment List</div>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link :to="{name: 'stockadjustment.create'}" class="menu-link">
              <div data-i18n="Stock Transfer Create">Stock Adjustment Create</div>
            </router-link>
          </li>
        </ul>
      </li>

      <li class="menu-item">
        <a href="javascript:void(0);" class="menu-link menu-toggle">
          <i class="menu-icon tf-icons fas fa-hand-holding-usd"></i>
          <div data-i18n="Purchase">Purchase</div>
        </a>

        <ul class="menu-sub">
          <li class="menu-item">
            <router-link :to="{name: 'purchase.index'}" class="menu-link">
              <div data-i18n="Purchase List"> Purchase List </div>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link :to="{name: 'purchase.create'}" class="menu-link">
              <div data-i18n="Purchase Create">Purchase Create</div>
            </router-link>
          </li>
        </ul>
      </li>

      <li class="menu-item">
        <a href="javascript:void(0);" class="menu-link menu-toggle">
          <i class="menu-icon tf-icons far fa-list-alt"></i>
          <div data-i18n="Products">Products</div>
        </a>

        <ul class="menu-sub">
          <li class="menu-item">
            <router-link :to="{name: 'product.index'}" class="menu-link">
              <div data-i18n="Product List"> Product List </div>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link :to="{name: 'product.create'}" class="menu-link">
              <div data-i18n="Product Create">Product Create</div>
            </router-link>
          </li>

          <li class="menu-item">
            <router-link :to="{name: 'category.index'}" class="menu-link">
              <div data-i18n="Category">Category</div>
            </router-link>
          </li>

          <li class="menu-item">
            <router-link :to="{name: 'brand.index'}" class="menu-link">
              <div data-i18n="Brand">Brand</div>
            </router-link>
          </li>
        </ul>
      </li>

      <!-- Apps & Pages -->
      <li class="menu-header small text-uppercase">
        <span class="menu-header-text">Users </span>
      </li>

      <li class="menu-item">
        <a href="javascript:void(0);" class="menu-link menu-toggle">
          <i class="menu-icon tf-icons ti ti-users"></i>
          <div data-i18n="Customers">Customers</div>
        </a>
        <ul class="menu-sub">
          <li class="menu-item">
            <router-link :to="{name: 'customer.index'}" class="menu-link">
              <div data-i18n="Customer List">Customer List</div>
            </router-link>
          </li>

          <li class="menu-item">
            <router-link :to="{name: 'customer.create'}" class="menu-link">
              <div data-i18n="Customer Create">Customer Create</div>
            </router-link>
          </li>
        </ul>
      </li>

      <li class="menu-item">
        <a href="javascript:void(0);" class="menu-link menu-toggle">
          <i class="menu-icon tf-icons ti ti-users"></i>
          <div data-i18n="Supplier">Supplier</div>
        </a>
        <ul class="menu-sub">
          <li class="menu-item">
            <router-link :to="{name: 'supplier.index'}" class="menu-link">
              <div data-i18n="Supplier List">Supplier List</div>
            </router-link>
          </li>

          <li class="menu-item">
            <router-link :to="{name: 'supplier.create'}" class="menu-link">
              <div data-i18n="Supplier Create">Supplier Create</div>
            </router-link>
          </li>
        </ul>
      </li>

       <!-- Apps & Pages -->
      <li class="menu-header small text-uppercase">
        <span class="menu-header-text">Setting & Configuration </span>
      </li>

      <li class="menu-item">
        <router-link :to="{name: 'warehouse.index'}" class="menu-link">
          <i class="menu-icon tf-icons fa-solid fa-warehouse"></i>
          <div data-i18n="Warehouse">Warehouse</div>
        </router-link>
      </li>

      <li class="menu-item">
        <router-link :to="{name: 'logisticcompany.index'}" class="menu-link">
          <i class="menu-icon tf-icons fas fa-truck"></i>
          <div data-i18n="Logistic">Logistic</div>
        </router-link>
      </li>

      <li class="menu-item">
        <router-link :to="{name: 'config.create'}" class="menu-link">
          <i class="menu-icon tf-icons fas fa-tools"></i>
          <div data-i18n="Config">Config</div>
        </router-link>
      </li>   
      
      
    <div class="ps__rail-x" style="left: 0px; bottom: 0px;"><div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div></div><div class="ps__rail-y" style="top: 0px; height: 265px; right: 4px;"><div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 49px;"></div></div></ul>
  </aside>
</template>


<script>
    export default {
        name: 'Sidebar',
    }
</script>
