<template>
    <nav
      class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
      id="layout-navbar">
      <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
        <a class="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
          <i class="ti ti-menu-2 ti-sm"></i>
        </a>
      </div>

      <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
        <!-- Search -->
        <div class="navbar-nav align-items-center">
          <div class="nav-item navbar-search-wrapper mb-0">
            <a class="nav-item nav-link search-toggler d-flex align-items-center px-0" href="javascript:void(0);">
              <!-- <i class="ti ti-search ti-md me-2"></i> -->
              <!-- <span class="d-none d-md-inline-block text-muted">Search (Ctrl+/)</span> -->
              E-com Automation
            </a>
          </div>
        </div>
        <!-- /Search -->

        <ul class="navbar-nav flex-row align-items-center ms-auto">

          <!-- User -->
          <li class="nav-item navbar-dropdown dropdown-user dropdown">
            <a class="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
              <div class="avatar avatar-online">
                <img src="../../../public/assets/img/branding/avatar.png" alt class="h-auto rounded-circle" />
              </div>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a class="dropdown-item" href="pages-account-settings-account.html">
                  <div class="d-flex">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar avatar-online">
                        <img src="../../../public/assets/img/branding/avatar.png" alt class="h-auto rounded-circle" />
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <span class="fw-semibold d-block">{{$root.user.name}}</span>
                      <small class="text-muted">Admin</small>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <div class="dropdown-divider"></div>
              </li>
              <li>
                <router-link class="dropdown-item" :to="{name: 'profile'}">
                  <i class="ti ti-user-check me-2 ti-sm"></i>
                  <span class="align-middle">Profile</span>
                </router-link>
              </li>
              <li>
                <router-link class="dropdown-item" :to="{name: 'billingInvoice.index'}">
                  <span class="d-flex align-items-center align-middle">
                    <i class="flex-shrink-0 ti ti-credit-card me-2 ti-sm"></i>
                    <span class="flex-grow-1 align-middle">Billing</span>
                  </span>
                </router-link>
              </li>
              <li>
                <div class="dropdown-divider"></div>
              </li>
             
              <li>
                <a class="dropdown-item" @click="logout" target="_blank">
                  <i class="ti ti-logout me-2 ti-sm"></i>
                  <span class="align-middle">Log Out</span>
                </a>
              </li>
            </ul>
          </li>
          <!--/ User -->
        </ul>
      </div>

      <!-- Search Small Screens -->
      <div class="navbar-search-wrapper search-input-wrapper d-none">
        <input
          type="text"
          class="form-control search-input container-xxl border-0"
          placeholder="Search..."
          aria-label="Search..." />
        <i class="ti ti-x ti-sm search-toggler cursor-pointer"></i>
      </div>
    </nav>
</template>


<script>

export default {
    name: 'Header',
    methods:{
        logout(){
            this.$store.dispatch("auth/logout");
            // this.$toast(res.data.message, "success");
            localStorage.removeItem('accessToken')
            localStorage.removeItem('user');
            window.location.href = '/login'
        }
    }
}
</script>


<style scoped>

@media only screen and (max-width: 600px){
	.care-phone{display: none;}
}

</style>